const member_list = [
    {
      title: "Healthcare Data Mining",
      member: [
        {
          member_name: "Profressor Minh Do",
          member_image: require("../../images/body/team/member/Minh_Do.png"),
          school: 'VinUniversity/University of Illinois at Urbana-Champaign'
        },
        {
          member_name: "Profressor My Thai",
          member_image: require("../../images/body/team/member/My_Thai.png"),
          school: 'University of Florida'
        }
      ]
    },
    {
      title: "User Privacy And Engagement",
      member: [
        {
          member_name: "Dr. Pham Huy Hieu",
          member_image: require("../../images/body/team/member/Hieu_Pham.png"),
          school: 'VinUni-Illinois Smart Health Center/VinUniversity'
        },
        {
          member_name: "Profressor Minh Do",
          member_image: require("../../images/body/team/member/Minh_Do.png"),
          school: 'VinUniversity/University of Illinois at Urbana-Champaign'
        },
        {
          member_name: "Profressor Duc Tran",
          member_image: require("../../images/body/team/member/Duc_Tran.png"),
          school: 'VinUniversity/University of Massachusetts'
        },
        {
          member_name: "Dr. Nguyen Phi Le",
          member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
          school: 'Hanoi University of Science and Technology'
        }
      ]
    },
    {
      title: "Visual Recognition Of Medication",
      member: [
        {
          member_name: "Dr. Nguyen Thanh Hung",
          member_image: require("../../images/body/team/member/Nguyen_Thanh_Hung.png"),
          school: 'Hanoi University of Science and Technology'
        },
        {
          member_name: "Dr. Nguyen Phi Le",
          member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
          school: 'Hanoi University of Science and Technology'
        },
        {
          member_name: "Profressor Minh Do",
          member_image: require("../../images/body/team/member/Minh_Do.png"),
          school: 'VinUniversity/University of Illinois at Urbana-Champaign'
        },
        {
          member_name: "Dr. Pham Huy Hieu",
          member_image: require("../../images/body/team/member/Hieu_Pham.png"),
          school: 'VinUni-Illinois Smart Health Center/VinUniversity'
        }
      ]
    },
    {
      title: "Vietnamese OCR",
      member: [
        {
          member_name: "Dr. Nguyen Phi Le",
          member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
          school: 'Hanoi University of Science and Technology'
        },
        {
          member_name: "Dr. Pham Huy Hieu",
          member_image: require("../../images/body/team/member/Hieu_Pham.png"),
          school: 'VinUni-Illinois Smart Health Center/VinUniversity'
        },
        {
          member_name: "Dr. Nguyen Thanh Hung",
          member_image: require("../../images/body/team/member/Nguyen_Thanh_Hung.png"),
          school: 'Hanoi University of Science and Technology'
        },
        {
          member_name: "Profressor Minh Do",
          member_image: require("../../images/body/team/member/Minh_Do.png"),
          school: 'VinUniversity/University of Illinois at Urbana-Champaign'
        }
      ]
    },
    {
      title: 'Elastic Resource Allocation',
      member: [
        {
          member_name: "Profressor Minh Do",
          member_image: require("../../images/body/team/member/Minh_Do.png"),
          school: 'VinUniversity/University of Illinois at Urbana-Champaign'
        },
        {
          member_name: "Dr. Nguyen Thanh Hung",
          member_image: require("../../images/body/team/member/Nguyen_Thanh_Hung.png"),
          school: 'Hanoi University of Science and Technology'
        },
        {
          member_name: "Dr. Nguyen Phi Le",
          member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
          school: 'Hanoi University of Science and Technology'
        }
      ]
    }
  ];
  
  export default member_list;
  
